<template>
  <div class="app">
    <el-card class="box-card">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-button type="success" icon="el-icon-plus" @click="handleCreate('create')">添加頂層菜單</el-button>
        </el-form-item>

        <el-form-item>
          <el-button-group>
            <el-button icon="el-icon-circle-plus-outline" @click="checkedNodes(true)">展開</el-button>

            <el-button icon="el-icon-remove-outline" @click="checkedNodes(false)">收起</el-button>
          </el-button-group>
        </el-form-item>

        <el-form-item label="過濾">
          <el-input
            v-model="filterText"
            placeholder="填寫關鍵詞進行鍋爐"
            prefix-icon="el-icon-search"
            style="width: 240px;"
            :clearable="true"
          ></el-input>
        </el-form-item>
      </el-form>

      <el-row :md="24">
        <el-col :md="10" :lg="15">
          <el-tree
            v-if="hackReset"
            :data="data"
            class="tree-scroll"
            node-key="id"
            :props="treeProps"
            :highlight-current="true"
            :filter-node-method="filterNode"
            :default-expand-all="isExpandAll"
            @node-click="handleNodeClick"
            ref="tree"
          >
            <span class="custom-tree-node action" slot-scope="{ node, data }">
              <span class="brother-showing">
                <i :class="`iconfont ${data.icon}`"></i>
                <span :class="{'status-tree':!parseInt(data.isNav)}"> {{ node.label }}</span>
              </span>

              <span class="active" v-if="!data.isApp">
                <el-button
                  type="success"
                  size="mini"
                  v-if="isAdd(data.parentId) == 2 || data.parentId == 0"
                  @click.stop="handleAppend(data.id)"
                >添加</el-button>
                <el-button type="danger" size="mini" @click.stop="remove(data.id)">删除</el-button>
              </span>
            </span>
          </el-tree>
        </el-col>

        <el-col :md="14" :lg="9">
          <el-card class="box-card" shadow="never">
            <div slot="header">
              <span>{{statusTitle}}</span>
            </div>

            <el-form :model="form" :rules="rules" ref="form" label-width="80px">
              <el-form-item label="上級菜單">
                <el-cascader
                  v-model="form.parentId"
                  placeholder="不選擇表示頂層菜單，支持查詢"
                  :key="form.id"
                  :options="treeData"
                  :props="cascaderProps"
                  style="width: 100%;"
                  filterable
                  clearable>
                </el-cascader>
              </el-form-item>

              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="名稱" prop="label">
                    <el-input v-model="form.label" placeholder="填寫菜單名稱" :clearable="true" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="排序" prop="sort">
                    <el-input-number
                      v-model="form.sort"
                      :min="0"
                      :max="255"
                      style="width: 120px;"
                      controls-position="right"
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="圖標">
                    <el-input
                      placeholder="選擇菜單圖標"
                      v-model="form.icon"
                      class="input-with-select"
                      clearable
                    >
                      <i slot="prefix" :class="['el-input__icon',form.icon]"></i>
                      <el-button slot="append" icon="fa fa-th-large" @click="dialogVisible = true"></el-button>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="導航">
                    <el-switch v-model="form.isNav" active-value="1" inactive-value="0"></el-switch>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="20">
                  <el-form-item label="鏈接類型">
                    <el-radio-group v-model="form.linkType">
                      <el-radio label="0">模塊</el-radio>
                      <el-radio label="1">外鏈</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item :label="form.linkType=='1'?'外鏈地址':'路由地址'">
                <el-input v-model="form.path" :placeholder="form.linkType=='1'?'填寫格式: http://zooir.com':'填寫格式: /index'" :clearable="true" />
              </el-form-item>

              <el-form-item label="路由別名" v-if="form.linkType==0">
                <el-input v-model="form.name" placeholder="填寫路由別名" :clearable="true" />
              </el-form-item>


              <el-form-item label="備註" prop="remark">
                <el-input v-model="form.remark" placeholder="填寫備註" type="textarea" :rows="3" />
              </el-form-item>

              <el-button
                type="primary"
                :loading="formLoading"
                size="medium"
                style="float: right; margin-bottom: 12px;"
              @click="createOrUpdate">確定</el-button>
            </el-form>
          </el-card>
        </el-col>
      </el-row>

      <el-dialog
        title="圖標列表"
        :visible.sync="dialogVisible"
        width="323px"
        :before-close="handleClose"
      >
        <el-scrollbar class="icon-dialog-visible">
          <div class="class-row el-row">
            <div
              v-for="(item,index) in iconList"
              :key="index"
              class="icon-list el-col-4"
              @click="chooseIconBtn(item)"
            >
              <i :class="`fa fa-${item}`"></i>
            </div>
          </div>
        </el-scrollbar>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {ApiGetMenuList} from '@/utils/http'
export default {
  computed: {
    ...mapState({
      iconList: (state) => state.icon.list,
    }),
  },
  data() {
    return {
      dialogVisible: false,
      //iconList:[],

      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      menuStatus: "收起",

      isExpandAll: false,
      hackReset: true,
      expanded: [],
      filterText: "",
      treeModule: {},
      treeProps: {
        label: "label",
        children: "children",
      },
      cascaderProps: {
        value: "value",
        label: "label",
        children: "children",
        checkStrictly: true,
        emitPath: false,
      },
      auth: {
        add: false,
        del: false,
        set: false,
        status: false,
        move: false,
      },
      treeData: [],
      form: {
        id: 0,
        parentId: 0,
        appRunState: 0,
        icon: '',
        isApp: 0,
        linkType: '0',
        name: '',
        label: '',
        path: '',
        isNav: '1',
        sort: 0,
        remark:'',
      },
      rules: {
        label: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
          {
            max: 32,
            message: "长度不能大于 32 个字符",
            trigger: "blur",
          },
        ],
        sort: [
          {
            type: "number",
            message: "必须为数字值",
            trigger: "blur",
          },
        ],
        remark: [
          {
            max: 255,
            message: "长度不能大于 255 个字符",
            trigger: "blur",
          },
        ],
      },
      formStatus: "create",
      formLoading: false,

      statusTitle: "添加菜单",
    };
  },
  mounted() {
    let sh = setInterval(()=>{
      let data = this.$atk.Store.store.get('getMenuList')
      if(data) {
        this.treeInit()
        clearInterval(sh);
      }
    },100)
  },
  methods: {
    treeInit(menu){
      let that = this
      if(menu != undefined && menu.length > 0){
        that.data = menu;
      }else{
        that.data = that.$store.state.tab.menu;
      }
      let newList = [];
      let list = that.data;
      for (let o in list) {
        if (list[o].children[0]) {
          let children = list[o].children;
          let newchildren = [];
          for (let i in children) {
            newchildren.push({
              label: children[i].label,
              value: children[i].id,
            });
          }
          newList.push({
            label: list[o].label,
            value: list[o].id,
            children: newchildren,
          });
        } else {
          newList.push({
            label: list[o].label,
            value: list[o].id,
          });
        }
      }
      that.treeData = newList;
    },
    isAdd(uid) {
      let db = this.data;
      let db2 = db;
      let level = 1;
      for (let o in db) {
        if (db[o].id == uid) {
          level = 2;
          if (db[o].parentId) {
            for (let i in db2) {
              if (db2[i].id == db[o].parentId) {
                level = 3;
              }
            }
          }
        }
      }
      return level;
    },
    handleClose(done) {
      done();
    },
    // 选择icon
    chooseIconBtn(item) {
      this.form.icon = "fa fa-" + item;
      this.dialogVisible = false;
    },
    // 过滤菜单
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    },
    // 重置表单
    resetForm() {
      this.form = {
        id: 0,
        parentId: 0,
        appRunState: 0,
        icon: '',
        isApp: 0,
        linkType: '0',
        name: '',
        label: '',
        path: '',
        isNav: '1',
        sort: 0,
        remark:'',
      };
    },
    // 重置元素
    resetElements(val = "create") {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });

      this.formStatus = val;
      this.formLoading = false;
    },
    // 展开或收起节点
    checkedNodes(isExpand) {
      this.filterText = "";
      this.hackReset = false;
      this.$nextTick(() => {
        this.isExpandAll = isExpand;
        this.hackReset = true;
      });
    },
    // 添加菜单表单初始化
    handleCreate(status) {
      this.statusTitle = "添加菜单";
      this.resetForm();
      this.resetElements(status);
    },
    // 追加菜单
    handleAppend(key) {
      this.handleCreate("create");
      this.$refs.tree.setCurrentKey(key);
      this.form.parentId = key;
    },
    // 点击树节点事件
    handleNodeClick(data) {
      this.statusTitle = "编辑菜单";

      this.resetForm();
      this.resetElements("update");

      this.form = {
        ...data,
        linkType: data.linkType.toString(),
        isNav: data.isNav.toString(),
      };
    },
    // 添加/更新菜单
    createOrUpdate() {
      let that = this
      that.$refs.form.validate((valid) => {
        if (valid) {
          that.formLoading = true;
          that.$http.POST('/v1/auth/addOrEditMenu',that.form,function (ret) {
              if (ret.errcode == 0) {
                that.$atk.Store.store.remove('getMenuList')
                ApiGetMenuList('/v1/auth/getMenuBarList')
                let sh = setInterval(()=>{
                  let data = that.$atk.Store.store.get('getMenuList')
                  if(data) {
                    that.$store.state.tab.menu = data.menu
                    that.$store.state.tab.tabsList = data.tabsList
                    that.treeInit()
                    that.resetForm();
                    that.$message.success(ret.errmsg);
                    that.formLoading = false;
                    clearInterval(sh);
                  }
                },100)
              } else if (ret.errcode >= 1) {
                that.formLoading = false;
                that.$message({
                  message: ret.errmsg,
                  type: 'warning'
                })
              }
          },(err)=> {
              that.formLoading = false;
              that.$notify({
                title: '警告',
                message: err,
                type: 'warning'
              })
            }
          )
        }
      });
    },
    // 删除菜单
    remove(key) {
      let that = this
      this.$confirm("确定要执行该操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
          that.$http.DELETE('/v1/auth/delMenu?id='+key,function (ret) {
              if (ret.errcode == 0) {
                that.$refs.tree.remove(that.$refs.tree.getNode(key));
                that.$atk.Store.store.remove('getMenuList')
                ApiGetMenuList('/v1/auth/getMenuBarList')
                let sh = setInterval(()=>{
                  let data = that.$atk.Store.store.get('getMenuList')
                  if(data) {
                    that.$store.state.tab.menu = data.menu
                    that.$store.state.tab.tabsList = data.tabsList
                    that.treeInit()
                    that.$message.success(ret.errmsg);
                    that.formLoading = false;
                    clearInterval(sh);
                  }
                },100)

              } else if (ret.errcode >= 1) {
                that.$message({
                  message: ret.errmsg,
                  type: 'warning'
                })
              }
          },(err)=> {
              that.$notify({
                title: '警告',
                message: err,
                type: 'warning'
              })
            }
          )
        }).catch(() => {});
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>
<style scoped lang="scss">
  .status-tree{
    color: #c0c4cc;
    text-decoration: line-through;
  }
.box-card {
  .tree-scroll {
    max-height: 615px;
    overflow: auto;
    padding-bottom: 1px;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .brother-showing {
      i {
        width: 13px;
      }
      .iconfont {
        font-size: 10px;
        vertical-align: baseline;
      }
    }
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .active {
      button {
        height: 20px;
        padding: 3px 10px;
      }
    }
  }
  .active {
    display: none;
  }
  .action:hover .active {
    display: block;
  }
  .icon-dialog-visible {
    height: 500px;
    .icon-list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      color: #7a7b7e;
      font-size: 16px;
    }
    .icon-list:hover {
      cursor: pointer;
      background: #fff;
      border: 1px solid #ebeef5;
      height: 40px;
      padding: 3px;
      z-index: 2000;
      color: #262627;
      line-height: 1.4;
      text-align: justify;
      font-size: 30px;
      -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      word-break: break-all;
    }
  }
}
</style>
